export default class FakeStore<T> {
  items: (T & { _id: string })[] = [];
  save(item: T) {
    const record = { _id: this.items.length + "", ...item };
    this.items.push(record);
    return record;
  }
  load() {
    return [...this.items];
  }
}
