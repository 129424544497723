import React, { ReactElement, FunctionComponent } from "react";
import Domain, { Command } from "../domain/domain";
import FakeStore from "../domain/fake-store";
import { DateTime } from "luxon";
import Layout from "./layout";
import Header from "./header";
import Forecast from "./forecast";
import Form from "./form";

export default class App extends React.Component {
  store: FakeStore<Command> = new FakeStore<Command>();
  domain: Domain = new Domain(this.store, { time: () => DateTime.local() });
  state: {
    events: Command[];
    nextJobEstimate: { days: number };
    nextJobName?: string;
  } = {
    events: this.store.items,
    nextJobEstimate: { days: 1 }
  };
  render() {
    return (
      <Layout
        menu={
          <div>
            <Header />
            <hr />
            <Form domain={this.domain} refresh={() => this.forceUpdate()} />
          </div>
        }
        content={<Forecast domain={this.domain} />}
      />
    );
  }
  submit() {
    this.domain.addJob({
      estimate: this.state.nextJobEstimate,
      name: this.state.nextJobName
    });
    this.refresh();
  }
  refresh() {
    this.setState({ events: this.store.items });
  }
  setJobEstimate(value: string): void {
    this.setState({ nextJobEstimate: { days: parseInt(value) } });
  }
  setJobName(value: string): void {
    this.setState({ nextJobName: value });
  }
}
