import React, { ReactElement } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class Layout extends React.Component {
  props: {
    menu: ReactElement;
    content: ReactElement;
  };
  render() {
    const { menu, content } = this.props;
    return (
      <Container fluid>
        <Row
          style={{
            minHeight: "100vh"
          }}
        >
          <Col
            xs={12}
            md={4}
            style={{ background: "#41DDD6", color: "#402F49" }}
          >
            {menu}
          </Col>

          <Col xs={12} md={8}>
            {content}
          </Col>
        </Row>
      </Container>
    );
  }
}
