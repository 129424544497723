import React from "react";
import Domain, { JobDetail } from "../domain/domain";
import { DateTime } from "luxon";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

export class Job extends React.Component {
  props: {
    job: JobDetail;
    jobNumber: number;
  };
  render() {
    const { job, jobNumber } = this.props;
    return (
      <Card>
        <Card.Body style={{ minHeight: "100px" }}>
          <div className="float-right text-right">
            <span style={{ display: "block", fontSize: "9px" }}>
              Est. Start:
            </span>
            <span>{job.startDate.toLocaleString(DateTime.DATE_SHORT)}</span>
          </div>
          <Card.Title>{job.name || `# ${jobNumber}`}</Card.Title>
        </Card.Body>
        <Card.Footer>
          <span>Estimate:</span>{" "}
          <b className="font-bold">{job.estimate.days} days</b>
        </Card.Footer>
      </Card>
    );
  }
}
export class StartDateItem extends React.Component {
  props: {
    nextAvailableStartDay: DateTime;
  };
  render() {
    const { nextAvailableStartDay } = this.props;
    return (
      <div>
        <span style={{ fontWeight: "bold" }}>
          {nextAvailableStartDay.toLocaleString(DateTime.DATE_HUGE)}
        </span>
      </div>
    );
  }
}
export default class Forecast extends React.Component {
  props: {
    domain: Domain;
  };
  render() {
    const { domain } = this.props;
    const { jobs, nextAvailableStartDay } = domain.forecastReport();
    return (
      <Container fluid>
        <Row>
          {jobs.length === 0 ? (
            <Col md="6" xl="4" className="my-2">
              <Card>
                <Card.Body>
                  <Card.Title className="text-center">
                    No Projects Yet
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ) : (
            jobs.map((job, i) => {
              return (
                <Col md="6" xl="4" className="my-2">
                  <Job key={i} job={job} jobNumber={i + 1} />
                </Col>
              );
            })
          )}
        </Row>
        <hr />
        <div>
          <h5>Next Available Start Date:</h5>
          <Card className="text-center">
            <Card.Body>
              {<StartDateItem nextAvailableStartDay={nextAvailableStartDay} />}
            </Card.Body>
          </Card>
        </div>
      </Container>
    );
  }
}
