import React from "react";
import Domain from "../domain/domain";
import { default as FormElement } from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default class Form extends React.Component {
  props: {
    domain: Domain;
    refresh: () => void;
  };
  state: {
    events: number;
    nextJobEstimate: { days: number };
    nextJobName?: string;
  } = {
    events: 0,
    nextJobEstimate: { days: 1 }
  };
  render() {
    return (
      <FormElement
        onSubmit={e => {
          e.preventDefault();
          this.submit();
        }}
      >
        <legend>Add a new project</legend>
        <FormElement.Group controlId="formBasicEmail">
          <FormElement.Label>Project Name</FormElement.Label>
          <FormElement.Control
            type="text"
            onChange={e => this.setJobName(e.target.value)}
          />
        </FormElement.Group>
        <FormElement.Group controlId="formBasicEmail">
          <FormElement.Label>Project Estimate</FormElement.Label>
          <FormElement.Control
            type="text"
            onChange={e => this.setJobEstimate(e.target.value)}
          />
          <FormElement.Text>in days</FormElement.Text>
        </FormElement.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </FormElement>
    );
  }
  submit() {
    this.props.domain.addJob({
      estimate: this.state.nextJobEstimate,
      name: this.state.nextJobName
    });
    this.refresh();
  }
  refresh() {
    this.props.refresh();
  }
  setJobEstimate(value: string): void {
    this.setState({ nextJobEstimate: { days: parseInt(value) } });
  }
  setJobName(value: string): void {
    this.setState({ nextJobName: value });
  }
}
