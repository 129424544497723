import { DateTime } from "luxon";

export type Time = { days?: number; hours?: number };

export type JobDescription = {
  estimate: Time;
  name?: string;
};

export type Saver<T> = {
  save(event: T): T & { _id: string };
};
export type Loader<T> = {
  load(): (T & { _id: string })[];
};
export function createEventHandler<
  Event extends Command,
  Args extends any[]
>(factory: { new (...args: Args): Event }) {
  return function emitEvent(this: Domain, ...args: Args) {
    return this.store.save(new factory(...args));
  };
}

export class AddJobCommand {
  constructor(public jobDescription: JobDescription) {}
}

export function createQueryHandler<
  Query extends {
    render(events: Command[], context: ExecutionContext): any;
  },
  Args extends any[]
>(factory: { new (...args: Args): Query }) {
  return function renderReport(
    this: Domain,
    ...args: Args
  ): ReturnType<Query["render"]> {
    const query = new factory(...args);

    return query.render(this.store.load(), this.context);
  };
}
export type JobDetail = JobDescription & { startDate: DateTime };
export class ForecastReportQuery {
  constructor() {}
  render(events: Command[], context: ExecutionContext) {
    const jobs: JobDescription[] = events.map(e => e.jobDescription);
    let nextAvailableStartDay = context.time();
    const formattedJobs: JobDetail[] = jobs.map(jobDescription => {
      const formattedJob = {
        ...jobDescription,
        startDate: nextAvailableStartDay
      };
      const days = jobDescription.estimate.days || 0;
      const weeks = Math.floor(days / 5);
      if (weeks >= 1) {
        nextAvailableStartDay = nextAvailableStartDay.plus({
          weeks
        });
      }
      let remainder = days - weeks * 5;
      const daysTillWeekend = 6 - nextAvailableStartDay.weekday;
      if (daysTillWeekend <= remainder) {
        nextAvailableStartDay = nextAvailableStartDay.plus({
          days: daysTillWeekend + 2
        });
        remainder = remainder - daysTillWeekend;
      }
      nextAvailableStartDay = nextAvailableStartDay.plus({
        days: remainder
      });
      return formattedJob;
    });
    return {
      nextAvailableStartDay,
      jobs: formattedJobs
    };
  }
}

export type ExecutionContext = {
  time(): DateTime;
};
export type Command = AddJobCommand;
export default class Domain {
  constructor(
    protected store: Saver<Command> & Loader<Command>,
    protected context: ExecutionContext
  ) {}
  addJob = createEventHandler(AddJobCommand);
  forecastReport = createQueryHandler(ForecastReportQuery);
}
